/*
#landing-evento * {
    margin-bottom: 30px !important;
}
*/

#landing-evento {
    background: url('../../public/img/landing/background.jpg');
    color: white;
    font-family: 'Jura';
}

#landing-evento-mobile {
    background: url('../../public/img/landing/background.jpg');
}

#landing-evento p {
    background: none;
    padding: 0;
    width: 100%;
}

#landing-evento h1, #landing-evento-mobile h1 {
    color: lightgray;
    text-shadow:
        0 0 3px #20B4E3,
        0 0 5px #20B4E3,
        0 0 10px white,
        0 0 20px white,
        0 0 40px white,
        0 0 45px white,
        0 0 50px white,
        0 0 75px white;
    margin-bottom: 50px !important;
}

#landing-evento h2 {
    margin-bottom: 100px;
}

#landing-evento .carousel-inner {
    margin-top: 0;
}

.img-symbol {
    -webkit-filter: invert(1);
    filter: invert(1);
    margin: 40px 0;
    width: 100px;
}

.barqia-purple-text {
    color: lightgray;
    text-shadow:
        0 0 3px #EA37A3,
        0 0 5px #EA37A3,
        0 0 10px #EA37A3,
        0 0 20px #EA37A3,
        0 0 40px #EA37A3,
        0 0 45px #EA37A3,
        0 0 50px #EA37A3,
        0 0 75px #EA37A3;
}