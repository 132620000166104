.hide-on-desktop {
    display: none;
}

.poligonos {
    display: none;
}

#story-mobile {
    display: none;
}

#story-desktop {
    position: relative;
}

.carousel-inner {
    display: inline-block;
}

#container-about-us {
    padding: 0;
}

#panel_3_i {
    margin-top: -130px;
    rotate: 180deg;
}

/*

#first-p-our-mission {
    background: url('/public/img/panel_1_i.png') no-repeat;
}

#first-p-our-mission-2 {
    background: url('/public/img/panel_1_n_i.png')
}

#third-p-our-mission {
    background: url('/public/img/poligonos_2.png');
}

#fourth-p-our-mission {
    background: url('/public/img/panel_3_i.png')
}

*/

@media (min-width: 576px) {
        .container, .container-sm {
        max-width: none;
    }
}

@media screen and (max-width: 600px) {
    .hide-on-mobile {
        display: none;
    }

    .poligonos {
        display: block;
    }

    #story-mobile {
        display: block;
    }

    #h1-about-us-our-mission {
        margin-top: 43px;
    }

    /*
    p {
        background: linear-gradient(to bottom,  #020409 0%,#05101b 50%,#020409 100%);
        border-radius: 10px;
        font-size: 30px;
        font-weight: 600;
        margin: auto;
        padding: 20px 40px;
        width: 75%;
    }
    */

    #div-img-story-title {
        margin-bottom: 5px;
        margin-top: -2100px;
    }

    #div-img-story-title img {
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
    }

    #about-us-content {
        margin-bottom: 50px;
    }

    #about-us-content {
        background: rgba(0, 0, 0, 0.8);
    }
}