html, body {
  font-family: 'Jura' !important;
  margin: 0;
  padding: 0;
}

html {
  background: black;
}

p {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#020409+0,05101b+50,020409+100 */
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  font-size: 20px;
  text-align: justify;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 40px;
}

#navbar-laser {
  position: relative;
  z-index: 1;
  width: 100%;
}

#nav-bar {
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 50px;
    text-transform: uppercase;
    z-index: 2;
}

#nav-bar a {
  transition: 0.2s
}

#nav-bar a.nav-bar-selected-link {
  border-bottom: 5px solid white;
}

#nav-bar a:hover {
  border-bottom: 5px solid white;
}

#nav-bar:hover a.nav-bar-selected-link {
  border-bottom: none;
}

#nav-bar:hover a.nav-bar-selected-link:hover {
  border-bottom: 5px solid white;
}

#barqia-footer-gif {
    margin-top: -100px;
    margin-bottom: -100px;
    width: 500px;
}

#footer-text {
  left: -30px;
  position: relative;
}

.neonText {
  color: #B7CFCC;
  font-size: 49px;
  text-shadow:
      0 0 3px #B7CFCC,
      0 0 5px #B7CFCC,
      0 0 10px #B7CFCC,
      0 0 21px #005A4D,
      0 0 41px #005A4D,
      0 0 46px #005A4D,
      0 0 51px #005A4D,
      0 0 75px #005A4D;
}

.bg-body-tertiary {
    background: rgba(0, 0, 0, 0.7) !important;
    color: white;
    padding: 20px 0;
    z-index: 1;
}

.navBar-toggle.navbar-toggler.collapsed {
    background: black;
    border: 2px solid white;
}

#barqia-symbol {
    width: 60px;
    margin: 0 20px;
}

#barqia-title {
    width: 130px;
}

#offcanvasNavbar-expand-xxl {
    background: black !important;
}

.offcanvas-header {
    background: black;
    border-bottom: 1px solid lightgray;
}

.desktop-link:hover {
    text-decoration: underline;
}

.btn-close {
  filter: brightness(0) invert(1);
  font-size: 25px;
  margin-right: 10px;
}

#general-navbar .barqia-button {
    background: #E338D6;
}

        #general-navbar .barqia-button:hover {
            background: #E338D6;
        }

#barqia-footer-gif {
  width: 375px;
}

.container-desktop, .container-mobile {
  overflow: hidden;
}

@media only screen and (min-width: 600px) {
    #offcanvasNavbar-expand-xxl, .offcanvas-body, .navBar-nav, .navBar-toggle {
        display: none;
    }

    .container-desktop {
      display: block;
      padding: 0;
    }

    .container-mobile {
      display: none;
    }
}

.container-desktop, .container-mobile {
  padding: 0;
}

@media only screen and (max-width: 600px) {
    #barqia-title, .desktop-link {
        display: none;
    }

    .container-desktop {
      display: none;
    }

    .container-mobile {
      display: block;
    }

    .container-mobile p {
      background: none;
      border-radius: 0px;
      color: white;
      text-align: left;
      margin: none;
      padding: 30px 50px;
      width: 100%;
    }

    .container-mobile img {
      width: 100%;
    }

    .nav-link {
      font-size: 28px;
    }

    #navbar-barqia-symbol {
      width: 70px;
    }

    #img-home-2 {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        width: 100%;
    }

    #img-search {
      width: 100px !important;
      position: absolute;
      top: 15px !important;
      left: 120px !important;
    }

    .input-group {
      margin: 20px 0;
    }

    .form-control {
      padding: 20px;
    }

    .nav-bar-selected-link {
      background: white;
      color: black;
      text-decoration: none;
    }
}

/* Símbolo en blanco - botón de navegación (mobile) */

.navbar-toggler-icon {
  filter: brightness(0) invert(1);
}

/* Background barra de navegación mobile

.offcanvas-header {
  background: url('/public/img/fondo-landing.png');
}

 */
