#home-container {
    color: white;
}

#home-content {
    position: relative;
}

#nav-bar a {
    color: white;
    font-family: 'Jura' !important;
    font-weight: 800;
    margin-left: 30px;
    text-decoration: none;
}

#nav-bar img {
    display: none;
}

@media screen and (max-width: 600px) {
    p {
        text-align: center !important;
    }

    #home-container {
        display: none;
    }
    
    /*
    p {
        background: linear-gradient(to bottom,  #020409 0%,#05101b 50%,#020409 100%);
        border-radius: 10px;
        font-size: 30px;
        font-weight: 600;
        margin: auto;
        padding: 20px 40px;
        width: 75%;
    }
    */

    #nav-bar {
        position: relative;
        right: 0;
        top: 0;
    }

    #nav-bar a {
        display: block;
    }

    #nav-bar img {
        display: block;
    }

    #navbar-laser {
        display: none;
    }
    
    #home-container .container div img {
        width: 100%;
    }
    
    #logo-inicio-reflejo {
        display: none;
    }
    
    #barqia-symbol-gif-container {
        display: none;
    }
}

/*
#home-body {
    background: url('../../public/img/poligonos.png') no-repeat center;
}
#first-p-welcome-to-barqia {
    background: url('/public/img/panel_2_i.png');
}

#second-p-welcome-to-barqia {
    background: url('/public/img/panel_3_i.png')
}

#third-p-welcome-to-barqia {
    background: url('/public/img/panel_5_i.png');
}
*/