#img-about-us {
    margin-top: -115px;
    position: relative;
    width: 100%;
    z-index: '0';
}

#img-search {
    width: 200px;
    position: absolute;
    top: 50px;
    left: 42%;
}

#img-home-2 {
    margin-top: -149px;
    width: 100%;
}

.titulo-neon {
    color: lightgray;
    text-shadow: 0 0 3px #20B4E3, 0 0 5px #20B4E3, 0 0 10px white, 0 0 20px white, 0 0 40px white, 0 0 45px white, 0 0 50px white, 0 0 75px white;
}

@media screen and (min-width: 600px) {
    #barqia-symbol {
        display: inline-block;
    }

    #segundo-container {
        width: 60%
    }
}

@media screen and (max-width: 600px) {
    #segundo-container {
        width: 100%
    }

        #segundo-container {
            width: 100%
        }

    #barqia-symbol {
        display: none;
    }

    #menu-hamburguesa {
        background: black;
    }
}

.banda-vertical {
    background: #0A111C;
    height: 950px;
    width: 100px;
    margin-left: 40px;
    margin-top: -80px;
    position: absolute;
    z-index: -1;
}
